@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system,
    'Roboto',
    'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none;

}

body::-webkit-scrollbar {
  width: 0;
}

@-moz-document url-prefix() {

  /* Disable scrollbar Firefox */
  html {
    scrollbar-width: none;
  }
}

body {
  /* remove default margin */
  scrollbar-width: none;
  /* Also needed to disable scrollbar Firefox */
  -ms-overflow-style: none;
  /* Disable scrollbar IE 10+ */
  /* overflow-y: scroll; */

}

body .MuiPopover-root>.MuiPopover-paper {
  max-width: 300px;
  max-height: 200px;
}

code {
  font-family: 'Comfortaa',
    cursive;
}

.one-author {
  cursor: pointer;
  color: #1976d2;
}

.one-people {
  cursor: pointer;
  color: #1976d2;
}

.one-author:hover {
  text-decoration: underline;
}

.one-people:hover {
  text-decoration: underline;
}

.one-publication {
  margin-bottom: 8px;
}

#start_year,
#finish_year {
  max-width: 80px;
}

.current-exh {
  opacity: 0.6;
}

.hasNegative {
  background-color: rgba(2, 18, 135, 0.05) !important;
}